import React from "react";

export class Promoute extends React.Component<{}>
{
	render()
	{
		return <div className={"bg-contrasting"}>
			<div className={"container text-general display-between promoute"}>
				<h4>продаж</h4>
				<h4>монтаж</h4>
				<h4>сервіс</h4>
			</div>
		</div>;
	}
}
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { contact } from "../Variable";
import Attribute from "./Attribute";

export class Contact extends React.Component
{
	render()
	{
		return (
			<Container>
				<h4>Контакти</h4>
				<Row>
					<Col sm={3}>
						<Attribute attributes={contact.phone}/>
					</Col>
				</Row>

			</Container>
		)
	}
}
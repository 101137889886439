import React from "react";
import { Container } from "react-bootstrap";
import { Image, ImageGroup } from "react-fullscreen-image";
import { ICertificate, IPictures } from "@common/interfaces/records";
import { backend } from "../index";

export class Certificate1 extends React.Component<{}, {certificates: ICertificate[], text: string}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {certificates: [], text: ''};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<h4>Сертифікати</h4>
				<div>
					{this.state.certificates.map(item => (
						<>
							<h5>{item.name}</h5>
							<div className={'image-fullscreen'}>

								<Certificates pictures={item.pictures}/>
							</div>
						</>
					))}
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let certificates = await backend.getCertificates()
		this.setState(_ => ({
			certificates: certificates,
			text: ''
		}));
	}
}

class Certificates extends React.Component<{pictures: IPictures[]}>
{
	render()
	{
		return (
			<ImageGroup>
				<ul className="images">
					{this.props.pictures.map(i => (
						<li key={i.src}>
							<Image src={`${i.src}`} alt="mountains"/>
						</li>
					))}
				</ul>
			</ImageGroup>
		);
	}
}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ICategory, IProduct, ISection } from "../../../common/interfaces/records";
import { backend } from "../index";

export class Category1 extends React.Component<{match: {params: {number: string;};};}, {products: IProduct[], category: ICategory}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {products: [], category: {name: '', id: '', brand_ids:[], image: '', pictures:[]}};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<div className={"category"}>
					<h4>{this.state.category.name}</h4>
					<TodoList products={this.state.products}/>
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let products = await backend.getProductWithCategory(this.props.match.params.number)
		this.setState(_ => ({
			products: products
		}));
		let category = await backend.getCategory(this.props.match.params.number)
		this.setState(_ => ({
			category: category
		}));
	}
}

class TodoList extends React.Component<{products: IProduct[]}>
{
	render()
	{
		return (
			<Row>
				{this.props.products.map(({id, name, pictures, product_attributes}) => (
					<Col sm={6} md={4} lg={3} key={id}>
						<Link to={`/product/${id}`}>
							<div className={"general-shadow title mb-4"}>
								<div className={"p-3 overflow-hidden display-center"}>
									{typeof pictures !== 'undefined' ? pictures.map((item, i) => (
											i == pictures.length - 1 ?
												<img src={`${item.src}`} alt={`${item.src}`} key={item.src}/> : ''))
										: ''}
								</div>
								<div className={"p-3 bg-highlight"}>
									<h5 className={"name"}>{name}</h5>
									{typeof product_attributes !== 'undefined' ?
									<hr/> : ''}
									{typeof product_attributes !== 'undefined' ?
										product_attributes.map(item => (
										<p>{item.attribute}</p>
									)) : ''}
								</div>
							</div>
						</Link>
					</Col>
				))}
			</Row>
		);
	}
}

import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IBrand, IPictures, ISection } from "../../../common/interfaces/records";
import { backend } from "../index";

export class Section1 extends React.Component<{match: {params: {number: string;};};}, {brands: IBrand[], section: ISection}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {brands: [], section: {name: '', id: ''}};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<div className={"section"}>
					<h4>{this.state.section.name}</h4>
					<Content brands={this.state.brands}/>
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let brands = await backend.getBrandsWithSection(this.props.match.params.number)
		this.setState(_ => ({
			brands: brands
		}));

		let section = await backend.getSection(this.props.match.params.number)
		this.setState(_ => ({
			section: section
		}));
	}
}

class Content extends React.Component<{brands: IBrand[]}>
{
	render()
	{
		return (
			<Row>
				{this.props.brands.map(brand => (
					<div className={"col-lg-6 col-sm-12 mt-3"} key={brand.id}>
						<div className={"tile general-shadow"}>
							<Link to={`/brand/${brand.id}`}>
								<Row>
									<div className={"col-md-6 d-none d-md-block overflow-hidden big-image"}>
										{typeof brand.pictures !== 'undefined' ? brand.pictures.map((item, i) => (
												i == 0 ?
													<img src={`${item.src}`} alt={`${brand.name}`} key={item.src}/> : ''))
											: ''}
									</div>
									<div className={"col-xs-12 col-md-6 direction-column-center"}>
										{typeof brand.pictures !== 'undefined' ?
											<BrandImage pictures_brand={brand.pictures}/> : ''}
										<Link className={"link"} to={`/brand/${brand.id}`}>Перейти до продукцій
											бренду</Link>
									</div>
								</Row>
							</Link>
						</div>
					</div>
				))}
			</Row>
		);
	}
}

class BrandImage extends React.Component <{pictures_brand: IPictures[]}>
{
	render()
	{
		return (
			<div className={"row brand-image justify-content-center  mt-5"}>
				{typeof this.props.pictures_brand !== 'undefined' ? this.props.pictures_brand.map((item, i) => (
						i != 0 ?
							<img className={'mr-1 ml-1'} src={`${item.src}`} alt={`${item.src}`} key={item.src}/> : ''))
					: ''}
			</div>
		);
	}
}
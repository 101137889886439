import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IProduct, IPictures } from "../../../common/interfaces/records";
import { backend } from "../index";
import { ImageGroup, Image } from 'react-fullscreen-image'

export class Product1 extends React.Component<{match: {params: {number: string;};};}, {text: string, product: IProduct}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {
			product: {
				name: '',
				id: '',
				category_ids: [],
				description: '',
				description_title: '',
				description1: '',
				description_title1: '',
				description2: '',
				description_title2: '',
				description3: '',
				description_title3: '',
				description4: '',
				description_title4: '',
				description5: '',
				description_title5: '',
				pictures: []
			},
			text: 'asdf'
		};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<div className={"product"}>
					<h4>{this.state.product.name}</h4>
					<p>{typeof this.state.product.product_attributes !== 'undefined' ? this.state.product.product_attributes.map(item => (
						<>{item.attribute} </>
					)) : ''}</p>
					<Row>
						<div className={"col-sm-12 col-lg-4 product-image p-3 mb-5"}>
							{typeof this.state.product.pictures !== 'undefined' ? this.state.product.pictures.map((item, i) => (
									i === this.state.product.pictures.length - 1 ?
										<img src={`${item.src}`} alt={`${item.src}`} key={item.src}/> : ''))
								: ''}
						</div>
						<Col sm={12} lg={8}>
							<Row>
								<Col sm={12} lg={6}>
									<h5>{this.state.product.description_title}</h5>
									<Description description={this.state.product.description}/>
								</Col>
								<Col sm={12} lg={6}>
									<h5>{this.state.product.description_title1}</h5>
									<Description description={this.state.product.description1}/>
								</Col>
								<Col sm={12} lg={6}>
									<h5>{this.state.product.description_title2}</h5>
									<Description description={this.state.product.description2}/>
								</Col>
								<Col sm={12} lg={6}>
									<h5>{this.state.product.description_title3}</h5>
									<Description description={this.state.product.description3}/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<h5>{this.state.product.description_title4}</h5>
							<div className={"table"}><Description description={this.state.product.description4}/></div>
						</Col>
					</Row>
					<div className={'image-fullscreen'}>
						<ProductImages pictures={this.state.product.pictures}/>
					</div>
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let product = await backend.getProduct(this.props.match.params.number)
		this.setState(_ => ({
			product: product,
			text: ''
		}));
	}
}

class Description extends React.Component<{description: string}>
{
	render()
	{
		return (
			<>
				<div dangerouslySetInnerHTML={{__html: this.props.description}} className={"mb-4"}/>
			</>
		);
	}
}

class ProductImages extends React.Component<{pictures: IPictures[]}>
{
	render()
	{
		return (
			<ImageGroup>
				<ul className="images">
					{this.props.pictures.map((item, i) => (
						i != this.props.pictures.length - 1 ?
							<li key={item.src}>
								<Image src={`${item.src}`} alt="mountains"/>
							</li> : ''
					))}
				</ul>
			</ImageGroup>
		);
	}
}

export const contact = {
	email: {icon: 'email', text: 'ses_group@ukr.net'},
	phone: [
		{icon: 'phone',text: '+38 (067) 370 29 89'},
		{icon: 'phone',text: '+38 (097) 123 66 11'},
		{icon: 'phone',text: '+38 (063) 203 43 63'},
		{icon: 'email', text: 'ses_group@ukr.net'}
	]
	, layout_contact: [
		{icon: 'email', text: 'ses_group@ukr.net'},
		{icon: 'phone',text: '+38 (067) 370 29 89'}
	]
}
import React from "react";
import { Container } from "react-bootstrap";
import { IWorks } from "@common/interfaces/records";
import { Link } from "react-router-dom";
import { backend } from "../index";

export class WorksPage extends React.Component<{}, {works: IWorks[], loading?: boolean}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {works: [], loading: true};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<h4>Наші роботи</h4>
				<div className={"row"}>
					{!this.state.loading ?
						this.state.works.map(item => (
							<div className={"col-lg-4 col-md-6 pt-3"} key={item.name}>
								<Link to={`/work/${item.id}`} className={"work-box general-shadow"}
								      key={item.name}>
									<div className={"work-image"}>
										{item.pictures_first !== undefined &&
										 <img src={`${item.pictures_first.src}`} alt={item.name}/>
										}
									</div>
									<h3>{item.name}</h3>
								</Link>
							</div>
						)) : "Загрузка..."}
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let works = await backend.getWorks()
		this.setState(_ => ({
			works: works,
			loading: false
		}));
	}
}

import React from "react";
import Image from 'react-bootstrap/Image'

export class Banner extends React.Component<{}>{
	render(){
		return(
			<div className={"banner"}>
				<Image src="./banner.jpg" fluid/>
				<div className={'text'}>
					<h4 className={"main-text"}>Спеценергосервіс</h4>
					<p className={"sub-text"}>Найважливіше для нас – надійність та гарантія бездоганної якості </p>
				</div>
			</div>

		)
	}
}

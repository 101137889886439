import React from "react";
import { Banner } from "./Banner"
import { Promoute } from "./Promoute"
import { About } from "./About"
import { HomeBrands, HomePartner } from "./Brand"

export class Home extends React.Component
{
	render()
	{
		return (
			<>
				<Banner/>
				<Promoute/>
				<About/>
				<HomeBrands/>
				<HomePartner/>
			</>
		)
	}
}
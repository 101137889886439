import { cloneElement } from "react";
import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	TopToolbar,
	CreateButton,
	ExportButton,
	Button,
	ShowButton,
	EditButton,
	sanitizeListRestProps,
	Create,
	Edit,
	SimpleForm,
	TextInput,
	DateInput,
	ReferenceManyField,
	DateField
} from 'react-admin';
import IconEvent from '@material-ui/icons/Event';

const ListActions = ({
	                     currentSort,
	                     className,
	                     resource,
	                     filters,
	                     displayedFilters,
	                     exporter, // you can hide ExportButton if exporter = (null || false)
	                     filterValues,
	                     permanentFilter,
	                     hasCreate, // you can hide CreateButton if hasCreate = false
	                     basePath,
	                     selectedIds,
	                     onUnselectItems,
	                     showFilter,
	                     maxResults,
	                     total,
	                     ...rest
                     }: {[_: string]: any}) => (
	<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		<CreateButton basePath={basePath}/>
		<ExportButton
			disabled={total === 0}
			resource={resource}
			sort={currentSort}
			filter={{...filterValues, ...permanentFilter}}
			exporter={exporter}
			maxResults={maxResults}
		/>
		{/* Add your custom actions */}
		<Button
			onClick={() => { alert('Your custom action'); }}
			label="Show calendar"
		>
			<IconEvent/>
		</Button>
	</TopToolbar>
);

ListActions.defaultProps = {
	selectedIds: [],
	onUnselectItems: () => null,
};

export const Section = ({permissions, ...props}: any) => (
	<List {...props} actions={<ListActions permissions={permissions} {...props} />}>
		<Datagrid>
			<TextField source="id"/>
			<TextField source="name"/>
			<ShowButton/>
			<EditButton/>
		</Datagrid>
	</List>
);

export const SectionCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="name" />
		</SimpleForm>
	</Create>
);

export const SectionEdit = (props: any) => (
	<Edit  {...props}>
		<SimpleForm>
			<TextInput source="name" />
		</SimpleForm>
	</Edit >
);

import { cloneElement } from "react";
import * as React from "react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IProduct, ITodoItem } from "../../../common/interfaces/records";
import { backend } from "../index";
import {
	List,
	Datagrid,
	TextField,
	ImageField,
	TopToolbar,
	CreateButton,
	ExportButton,
	EditButton,
	sanitizeListRestProps,
	Create,
	Edit,
	SimpleForm,
	TextInput,
	ImageInput,
	TabbedForm,
	FormTab, SelectArrayInput, ReferenceArrayInput, SingleFieldList, ChipField, ReferenceArrayField,ArrayInput,SimpleFormIterator, ReferenceInput, SelectInput, ReferenceField
} from 'react-admin';

const ProductActions = ({
	                        currentSort,
	                        className,
	                        resource,
	                        filters,
	                        displayedFilters,
	                        exporter, // you can hide ExportButton if exporter = (null || false)
	                        filterValues,
	                        permanentFilter,
	                        hasCreate, // you can hide CreateButton if hasCreate = false
	                        basePath,
	                        selectedIds,
	                        onUnselectItems,
	                        showFilter,
	                        maxResults,
	                        total,
	                        ...rest
                        }: {[_: string]: any}) => (
	<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		<CreateButton basePath={basePath}/>
		<ExportButton
			disabled={total === 0}
			resource={resource}
			sort={currentSort}
			filter={{...filterValues, ...permanentFilter}}
			exporter={exporter}
			maxResults={maxResults}
		/>
	</TopToolbar>
);

ProductActions.defaultProps = {
	selectedIds: [],
	onUnselectItems: () => null,
};

export const Product = ({permissions, ...props}: any) => (
	<List {...props} actions={<ProductActions permissions={permissions} {...props} />}>
		<Datagrid>
			{/*<ImageField className="thumbNailView" source="pictures" src="src" title="desc"/>*/}
			<TextField source="name"/>
			<ReferenceField label="Categories" source="category_ids" reference="categories">
				<TextField source="name" />
			</ReferenceField>
			<EditButton/>
		</Datagrid>
	</List>
);

let CKEditorData: string;
let InputData: string;
const dateFormatter = (v: any) => {InputData = '';InputData = v;return v;}
const dateParser = (v: any) => {InputData = ''; InputData = v;return CKEditorData;}
let CKEditorData1: string;
let InputData1: string;
const dateFormatter1 = (v: any) => {InputData1 = '';InputData1 = v;return v;}
const dateParser1 = (v: any) => {InputData1 = ''; InputData1 = v;return CKEditorData1;}
let CKEditorData2: string;
let InputData2: string;
const dateFormatter2 = (v: any) => {InputData2 = '';InputData2 = v;return v;}
const dateParser2 = (v: any) => {InputData2 = ''; InputData2 = v;return CKEditorData2;}
let CKEditorData3: string;
let InputData3: string;
const dateFormatter3 = (v: any) => {InputData3 = '';InputData3 = v;return v;}
const dateParser3 = (v: any) => {InputData3 = ''; InputData3 = v;return CKEditorData3;}
let CKEditorData4: string;
let InputData4: string;
const dateFormatter4 = (v: any) => {InputData4 = '';InputData4 = v;return v;}
const dateParser4 = (v: any) => {InputData4 = ''; InputData4 = v;return CKEditorData4;}

export const ProductCreate = (props: any) => (
	<Create {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="name"/>
				<ReferenceInput label="Categories" source="category_ids" reference="categories">
					<SelectInput optionText="name" />
				</ReferenceInput>
				<ImageInput source="pictures" label="Related pictures" accept="image/*" multiple="false">
					<ImageField source="src" title="title"/>
				</ImageInput>
				<ArrayInput source="product_attributes">
					<SimpleFormIterator>
						<TextInput source="attribute" label="attribute" />
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
			<FormTab label="d">
				<TextInput source='description_title' fullWidth/>
				<TextInput source='description' parse={dateParser} format={dateFormatter} defaultValue={CKEditorData}/>
				<CKEditor editor={ClassicEditor} data={InputData} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData = ''; InputData = '';}}
					onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData = data;}}
				/>
			</FormTab>
			<FormTab label="d1">
				<TextInput source='description_title1' fullWidth/>
				<TextInput source='description1' parse={dateParser1} format={dateFormatter1} defaultValue={CKEditorData1}/>
				<CKEditor editor={ClassicEditor} data={InputData1} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData1 = ''; InputData1 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData1 = data;}}
				/>
			</FormTab>
			<FormTab label="d2">
				<TextInput source='description_title2' fullWidth/>
				<TextInput source='description2' parse={dateParser2} format={dateFormatter2} defaultValue={CKEditorData2}/>
				<CKEditor editor={ClassicEditor} data={InputData2} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData2 = ''; InputData2 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData2 = data;}}
				/>
			</FormTab>
			<FormTab label="d3">
				<TextInput source='description_title3' fullWidth/>
				<TextInput source='description3' parse={dateParser3} format={dateFormatter3} defaultValue={CKEditorData3}/>
				<CKEditor editor={ClassicEditor} data={InputData3} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData3 = ''; InputData3 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData3 = data;}}
				/>
			</FormTab>
			<FormTab label="d4">
				<TextInput source='description_title4' fullWidth/>
				<TextInput source='description4' parse={dateParser4} format={dateFormatter4} defaultValue={CKEditorData4}/>
				<CKEditor editor={ClassicEditor} data={InputData4} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData4 = ''; InputData4 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData4 = data;}}
				/>
			</FormTab>
		</TabbedForm>
	</Create>
);

export const ProductEdit = (props: any) => (
	<Edit  {...props}>
		<TabbedForm>
			<FormTab label="Details">
				<TextInput source="name"/>
				<ReferenceInput label="Categories" source="category_ids" reference="categories">
					<SelectInput optionText="name" />
				</ReferenceInput>
				<ImageInput source="pictures" label="Related pictures" accept="image/*" multiple="true">
					<ImageField source="src" title="title"/>
				</ImageInput>
				<ArrayInput source="product_attributes">
					<SimpleFormIterator>
						<TextInput source="attribute" label="attribute"/>
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>
			<FormTab label="d">
				<TextInput source='description_title' fullWidth/>
				<TextInput source='description' parse={dateParser} format={dateFormatter} defaultValue={CKEditorData}/>
				<CKEditor editor={ClassicEditor} data={InputData} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData = ''; InputData = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData = data;}}
				/>
			</FormTab>
			<FormTab label="d1">
				<TextInput source='description_title1' fullWidth/>
				<TextInput source='description1' parse={dateParser1} format={dateFormatter1} defaultValue={CKEditorData1}/>
				<CKEditor editor={ClassicEditor} data={InputData1} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData1 = ''; InputData1 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData1 = data;}}
				/>
			</FormTab>
			<FormTab label="d2">
				<TextInput source='description_title2' fullWidth/>
				<TextInput source='description2' parse={dateParser2} format={dateFormatter2} defaultValue={CKEditorData2}/>
				<CKEditor editor={ClassicEditor} data={InputData2} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData2 = ''; InputData2 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData2 = data;}}
				/>
			</FormTab>
			<FormTab label="d3">
				<TextInput source='description_title3' fullWidth/>
				<TextInput source='description3' parse={dateParser3} format={dateFormatter3} defaultValue={CKEditorData3}/>
				<CKEditor editor={ClassicEditor} data={InputData3} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData3 = ''; InputData3 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData3 = data;}}
				/>
			</FormTab>
			<FormTab label="d4">
				<TextInput source='description_title4' fullWidth/>
				<TextInput source='description4' parse={dateParser4} format={dateFormatter4} defaultValue={CKEditorData4}/>
				<CKEditor editor={ClassicEditor} data={InputData4} config={{toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells', 'mediaEmbed', '|', 'undo', 'redo']}}
				          onInit={(editor: any) => {CKEditorData4 = ''; InputData4 = '';}}
				          onChange={(event: any, editor: {getData: () => any;}) => {const data = editor.getData(); CKEditorData4 = data;}}
				/>
			</FormTab>
		</TabbedForm>
	</Edit>
);

//export class Editor extends React.Component<{source: string}, {items: IProduct}>
//{
//	constructor(props: any)
//	{
//		super(props);
//		this.state = {
//			items: {category_ids: [], id: '', description: '', pictures: [], name: ''}
//		};
//		this.handleSubmit = this.handleSubmit.bind(this);
//		this.loadData().catch();
//	}
//
//	render()
//	{
//		return (
//			<>
//				<CKEditor
//					editor={ClassicEditor}
//					onInit={(editor: any) => {
//						// You can store the "editor" and use when it is needed.
//						console.log('Editor is ready to use!', editor);
//					}}
//					onChange={(event: any, editor: {getData: () => any;}) => {
//						const data = editor.getData();
////				console.log({event, editor, data});
//					}}
//					onBlur={(event: any, editor: any) => {
//						console.log('Blur.', editor);
//					}}
//					onFocus={(event: any, editor: any) => {
//						console.log('Focus.', editor);
//					}}
//				/>
//			</>
//		);
//	}
//
//	async handleSubmit(e: React.FormEvent<HTMLFormElement>)
//	{
//		const newItem = {
//			id: Date.now().toString(),
//			description: this.state.items.description,
//		};
//
//		await backend.postCKEditor<IProduct>(newItem);
//
//		this.setState(state => ({
//			items: state.items.concat(newItem),
//			text: ''
//		}));
//	}
//
//	async loadData()
//	{
//		let items = await backend.getProduct(this.props.match.params.number)
//		this.setState(_ => ({
//			items: items,
//		}));
//	}
//}

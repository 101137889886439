import React from "react";
import { Container } from "react-bootstrap";
import { Image, ImageGroup } from "react-fullscreen-image";
import { IWorks, IPictures } from "../../../common/interfaces/records";
import { backend } from "../index";

export class WorkPage extends React.Component<{match: {params: {number: string;};};}, {work: IWorks, loading?: boolean}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {work: {id: '', name: '', pictures: []}, loading: true};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<h4>Наші роботи</h4>
				<div>
					{!this.state.loading ?
							<div className={'image-fullscreen'}>
								<h3>{this.state.work.name}</h3>
								<Certificates pictures={this.state.work.pictures}/>
							</div>
						: "Загрузка..."}
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let work = await backend.getWork(this.props.match.params.number)
		this.setState(_ => ({
			work: work,
			loading: false
		}));
	}
}

class Certificates extends React.Component<{pictures: IPictures[]}>
{
	render()
	{
		return (
			<ImageGroup>
				<ul className="images">
					{this.props.pictures.map(i => (
						<li key={i.src}>
							<Image src={`${i.src}`} alt="mountains"/>
						</li>
					))}
				</ul>
			</ImageGroup>
		);
	}
}

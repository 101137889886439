import React from 'react'
import { Navbar, Nav } from "react-bootstrap";
import { ISection } from "@common/interfaces/records";
import { backend } from "../index";

export const navigation = {
	brand: {name: '/logo192.png', to: '/'},
	navbar: [
		{name: 'Про нас', to: '/#about'},
		{name: 'Контакти', to: '/contact'},
		{name: 'Сертифікати', to: '/certificates'},
		{name: 'Наші роботи', to: '/works'},
	]
};

const {brand, navbar} = navigation;

export class Header extends React.Component<{}, {brand: {name: string; to: string}, links: Array<{name: string, to: string}>, items: ISection[]}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {brand: brand, links: navbar, items: []};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Navbar bg="general navbar" expand="lg">
				<Header1 brand={this.state.brand} links={this.state.links} items={this.state.items}/>
			</Navbar>
		);
	}

	async loadData()
	{
		let items = await backend.getSections()
		this.setState(_ => ({
			items: items
		}));
	}
}

const Header1 = (props: {brand: {name: string; to: string}, links: Array<{name: string, to: string}>, items: ISection[]}) => {
	const {brand, links, items} = props;
//	links.splice(1, 0, Lal)
	const SectionsLinks: any = () => items.map((link: {name: string, id: string}) =>
		<>
			<Nav.Link href={`/section/${link.id}`}>{link.name}</Nav.Link>
		</>);
	const NavLinks: any = () => links.map((link: {name: string, to: string}) =>
		<Nav.Link href={link.to} key={link.name}>{link.name}</Nav.Link>
	);
	return (
		<>
			<Navbar.Brand href={brand.to}><img src={brand.name} className={"logo"} alt="Logo"/></Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav"/>
			<Navbar.Collapse id="basic-navbar-nav" className={"bg-white drop-down-menu"}>
				<Nav className="mr-auto">
				</Nav>
				<Nav>
					<Nav.Link href="/">Головна</Nav.Link>
					<SectionsLinks/>
					<NavLinks/>
				</Nav>
			</Navbar.Collapse>
		</>
	)
};


//async function Lal()
//{
//	let brands: ISection[];
//	brands = await backend.getSections()
////	console.log(brands)
////	brands = [{"name": "Запірна арматура", "id": "5f0c99ad95cc9211fdb8fd1d"}, {
////		"name": "Насосне обладнання",
////		"id": "5f0c99c795cc9211fdb8fd1e"}]
//	const NavLinks: any = () => brands.map((link: {name: string, id: string}) =>
//		<>
//			<Nav.Link href={`/section/${link.id}`}>{link.name}</Nav.Link>
//		</>);
//
//	return (<div><NavLinks/></div>)
//}

export default Header;

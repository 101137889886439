import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export class About extends React.Component<{}>
{
	render()
	{
		return <Container>
			<h4 id={"about"} className={"mt-5 d-flex justify-content-center align-items-end"}>Про нас</h4>
			<Row>
				<Col lg={6} className={"pt-2"}>
					<img src="./about.jpg" className={"d-none d-lg-block mw-100 mh-100"}/>
				</Col>
				<Col lg={6} className={"text-justify"}>
					<p>ПП «Спеценергосервіс груп» - одна з провідних компаній  у напрямку постачання сучасної та надійної трубопровідної запірно-регулюючої арматури та насосного обладнання для систем теплопостачання, водопостачання, водовідведення, вентиляції, кондиціонування, систем очистки води та транспортування нафтопродуктів і газу.
					</p>
					<p>Компанія є офіційним дилером з продажу обладнання відомих світових виробників, таких як: VEXVE, NAVAL, HAWLE та WILO. Продукція цих брендів  спеціально розроблена для застосування в складних експлуатаційних умовах із врахуванням потреби зручності монтажу та подальшої експлуатації.
					</p>
					<p>Звернувшись до нас Ви, гарантовано, отримаєте якісне, сучасне та надійне обладнання відомих світових брендів. До  продукції,  що постачається, надається відповідна технічна документація та сертифікати, тому наші замовники можуть бути впевненні в якості та оригінальності придбаного обладнання.
					</p>
				</Col>
			</Row>
			<Row>
				<Col lg={12} className={"text-justify"}>
					<p>Мета компанії – побудувати плідну та взаємовигідну співпрацю зі своїми клієнтами та стати для них надійним партнером, бути завжди на крок попереду потреб наших клієнтів та щодня перевершувати їх сподівання завдяки високому рівню сервісу. Ми працюємо для того, щоб якнайкраще зрозуміти та задовольнити потреби своїх замовників реалізувавши проєкт будь-якого рівня складності.
					</p>
				</Col>
			</Row>
		</Container>;
	}
}
import React from "react";
import { Link } from "react-router-dom";
import { ISection } from "../../../common/interfaces/records";
import { backend } from "../index";

export class Sections extends React.Component<{}, {sections: ISection[], text: string}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {sections: [], text: ''};
		this.loadData().catch();
	}

	render()
	{
		return (
			<div>
				<h4>Section</h4>
				<Content sections={this.state.sections}/>
			</div>
		);
	}

	async loadData()
	{
		let sections = await backend.getSections()
		this.setState(_ => ({
			sections: sections,
			text: ''
		}));
	}
}

class Content extends React.Component<{sections: ISection[]}>
{
	render()
	{
		return (
			<ul>
				{this.props.sections.map(item => (
					<li key={item.id}>
						<Link to={`/section/${item.id}`}>{item.name}</Link>
					</li>
				))}
			</ul>
		);
	}
}

import React from 'react';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Works, WorksCreate, WorksEdit } from "./admin/works";
import { Partner, PartnerCreate, PartnerEdit } from "./admin/partner";
import { Product, ProductCreate, ProductEdit } from "./admin/product";
import { Section, SectionCreate, SectionEdit } from "./admin/section";
import { Category, CategoryCreate, CategoryEdit } from "./admin/category";
import { Brand, BrandCreate, BrandEdit } from "./admin/brand";
import { Setting, SettingCreate, SettingEdit } from "./admin/setting";
import { Certificate, CertificateCreate, CertificateEdit } from "./admin/certificate";
import { Category1 } from "./components/Category";
import { WorkPage } from "./components/Work";
import { WorksPage } from "./components/Works";
import { dataProvider } from "./dataProvider";
import { authProvider } from "./authProvider";
import './stylesheet/App.scss';
import Navbar from "./components/NavBar";
import HeadLine from "./components/Headline";
import Footer from "./components/Footer";
import { Home } from "./components/Home";
import { Sections } from "./components/Sections";
import { Section1 } from "./components/Section";
import { Product1 } from "./components/Product";
import { Brand1 } from "./components/Brand";
import { Certificate1 } from "./components/Certificates";
import { Contact } from "./components/Contact";
import { Container, Row, Col } from "react-bootstrap";

import ProductIcon from '@material-ui/icons/People';

function App()
{
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/admin'>
					<AdminContent/>
				</Route>

				<Route exact path='/*'>
					<RootContent/>
				</Route>
			</Switch>
		</BrowserRouter>
	);
}

function RootContent()
{
	return (
		<Container fluid>
			<Row>
				<div className={"bg-subsidiary w-100"}>
					<Col>
						<HeadLine/>
					</Col>
				</div>
			</Row>
			<Row>
				<Col>
					<Navbar/>
					<hr/>
				</Col>
			</Row>
			<div className={'row content'}>
				<div className={"$bg-general w-100 pb-5 h-100"}>
					<Main/>
					{/*<Section/>*/}
					{/*<TodoApp/>*/}
				</div>
			</div>

			<Row>
				<div className={"bg-subsidiary w-100"}>
					<Col>
						<footer>
							<div className={'container-fluid'}>
								<Footer/>
							</div>
						</footer>
					</Col>
				</div>
			</Row>
		</Container>
	)
}

const Main = () => (
	<main>
		<Switch>
			<Route exact path='/' component={Home}/>
			<Route exact path='/sections' component={Sections}/>
			<Route path='/section/:number' component={Section1}/>
			<Route path='/brand/:number' component={Brand1}/>
			<Route path='/category/:number' component={Category1}/>
			<Route path='/product/:number' component={Product1}/>
			<Route path='/certificates' component={Certificate1}/>
			<Route path='/contact' component={Contact}/>
			<Route path='/works' component={WorksPage}/>
			<Route path='/work/:number' component={WorkPage}/>
			{/*<Route path='/schedule' component={Schedule}/>*/}
		</Switch>
	</main>
)

function AdminContent()
{
	return (
		<Admin dataProvider={dataProvider} authProvider={authProvider}>
			{/*<Resource name="items" create={ItemCreate} edit={ItemEdit} list={Item} icon={ItemIcon}/>*/}
			<Resource name="sections" create={SectionCreate} edit={SectionEdit} list={Section}/>
			<Resource name="brands" create={BrandCreate} edit={BrandEdit} list={Brand}/>
			<Resource name="partners" create={PartnerCreate} edit={PartnerEdit} list={Partner}/>
			<Resource name="works" create={WorksCreate} edit={WorksEdit} list={Works}/>
			<Resource name="categories" create={CategoryCreate} edit={CategoryEdit} list={Category}/>
			<Resource name="products" create={ProductCreate} edit={ProductEdit} list={Product} icon={ProductIcon}/>
			<Resource name="certificates" create={CertificateCreate} edit={CertificateEdit} list={Certificate}/>
			<Resource name="settings" create={SettingCreate} edit={SettingEdit} list={Setting}/>
		</Admin>
	)
}

export default App;

import { cloneElement } from "react";
import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	TopToolbar,
	CreateButton,
	ExportButton,
	EditButton,
	sanitizeListRestProps,
	Create,
	Edit,
	SimpleForm,
	TextInput,
	ImageInput, ImageField, SelectInput, ReferenceInput, ReferenceField
} from 'react-admin';

const ProductActions = ({
	                        currentSort,
	                        className,
	                        resource,
	                        filters,
	                        displayedFilters,
	                        exporter, // you can hide ExportButton if exporter = (null || false)
	                        filterValues,
	                        permanentFilter,
	                        hasCreate, // you can hide CreateButton if hasCreate = false
	                        basePath,
	                        selectedIds,
	                        onUnselectItems,
	                        showFilter,
	                        maxResults,
	                        total,
	                        ...rest
                        }: {[_: string]: any}) => (
	<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		<CreateButton basePath={basePath}/>
		<ExportButton
			disabled={total === 0}
			resource={resource}
			sort={currentSort}
			filter={{...filterValues, ...permanentFilter}}
			exporter={exporter}
			maxResults={maxResults}
		/>
	</TopToolbar>
);

ProductActions.defaultProps = {
	selectedIds: [],
	onUnselectItems: () => null,
};

export const Brand = ({permissions, ...props}: any) => (
	<List {...props} actions={<ProductActions permissions={permissions} {...props} />}>
		<Datagrid>
			<ImageField className="thumbNailView" source="pictures" src="src" title="desc"/>
			<TextField source="name"/>
			<ReferenceField label="Sections" source="section_ids" reference="sections">
				<TextField source="name"/>
			</ReferenceField>
			<EditButton/>
		</Datagrid>
	</List>
);

export const BrandCreate = (props: any) => (
	<Create {...props}>
		<SimpleForm>
			<ImageInput source="pictures" label="Main picture" accept="image/*" multiple="true">
				<ImageField source="src" title="title" option="test_option"/>
			</ImageInput>
			<TextInput source="name"/>
			<ReferenceInput label="Sections" source="section_ids" reference="sections">
				<SelectInput optionText="name"/>
			</ReferenceInput>
		</SimpleForm>
	</Create>
);

export const BrandEdit = (props: any) => (
	<Edit  {...props}>
		<SimpleForm>
			<ImageInput source="pictures" label="Main picture" accept="image/*" multiple="false">
				<ImageField source="src" title="title" option="test_option"/>
			</ImageInput>
			<TextInput source="name"/>
			<ReferenceInput label="Sections" source="section_ids" reference="sections">
				<SelectInput optionText="name"/>
			</ReferenceInput>
		</SimpleForm>
	</Edit>
);

import React from "react";
import Icon from '@material-ui/core/Icon';

const Attribute = (props: {
	attributes: Array<{icon: string, text: string}>
}) => {
	const {attributes} = props;

	const AttributesList: any = () => attributes.map((attribute: {icon: string, text: string}) =>
		<div className={'d-flex'} key={attribute.text}>
			<Icon className={"mr-3 ml-3"}>{attribute.icon}</Icon>
			<span>{attribute.text}</span>
		</div>);

	return (
		<AttributesList/>
	)
}

export default Attribute;

import React from 'react'
import Attribute from './Attribute'
import { contact } from "../Variable";


const Footer = () => {
	return (
			<div className={"headline text-general d-md-flex d-none justify-content-between align-items-center"}>
				<div>Spetsenerhoservis Copyright © 2020</div>
				<div className={'display-center'}><Attribute attributes={contact.layout_contact}/></div>
			</div>
	)
}

export default Footer;

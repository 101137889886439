import React from 'react'
import Attribute from './Attribute'
import { contact } from "../Variable";



const HeadLine = () => {
	return (

		<div className={"headline text-general d-md-flex d-none justify-content-between align-items-center"}>

			{/*<div>Продаж, монтаж, сервіс</div>*/}
			<div></div>
			<div className={"display-center"}><Attribute attributes={contact.layout_contact}/></div>

		</div>
	)
}

export default HeadLine;

import {
	IApiConf,
	IBrand,
	ICategory,
	ICertificate, IWorks, IPartner,
	IProduct,
	ISection,
} from "@common/interfaces/records";

const HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'Connection': 'close',
};

export class BackendService
{
	private _timeDifference = 0;
	private _tag = "[backend]";
	private _logRequest: boolean = true;
	private _logResponse: boolean = false;

	async getConf(): Promise<IApiConf>
	{
		let conf = await this.call<IApiConf>("conf");
		this._timeDifference = Math.abs(conf.time - Date.now() / 1000);

		if (this._logRequest)
			console.log(this._tag, "-> frontend time difference:", this._timeDifference);

		return conf;
	}

	async getSections(): Promise<ISection[]>
	{
		return this.call<ISection[]>("sections");
	}

	async getBrandsWithSection(id: string): Promise<IBrand[]>
	{
		return this.call<IBrand[]>(`brand_with_section/${id}`);
	}

	async getCategoriesWithBrand(id: string): Promise<ICategory[]>
	{
		return this.call<ICategory[]>(`categories_with_brand/${id}`);
	}

	async getBrand(id: string): Promise<IBrand>
	{
		return this.call<IBrand>(`brand/${id}`);
	}

	async getCategory(id: string): Promise<ICategory>
	{
		return this.call<ICategory>(`category/${id}`);
	}

	async getSection(id: string): Promise<ISection>
	{
		return this.call<ISection>(`section/${id}`);
	}

	async getBrands(): Promise<IBrand[]>
	{
		return this.call<IBrand[]>("brands");
	}

	async getPartners(): Promise<IPartner[]>
	{
		return this.call<IPartner[]>("partners");
	}

	async getProductWithCategory(id: string): Promise<IProduct[]>
	{
		return this.call<IProduct[]>(`product_with_category/${id}`);
	}

	async getProduct(id: string): Promise<IProduct>
	{
		return this.call<IProduct>(`product/${id}`);
	}

	async post<T>(item: T)
	{
		return this.call<boolean>("item", item, "POST");
	}

	async getCertificates(): Promise<ICertificate[]>
	{
		return this.call<ICertificate[]>("certificates");
	}

	async getWorks(): Promise<IWorks[]>
	{
		return this.call<IWorks[]>("works");
	}

	async getWork(id: string): Promise<IWorks>
	{
		return this.call<IWorks>(`work/${id}`);
	}

	async postCKEditor<T>(item: T)
	{
		return this.call<boolean>("ckeditor", item, "POST");
	}

	private async call<T>(path: string, params: unknown = {}, method: string = "GET"): Promise<T>
	{
		let url = `${process.env.REACT_APP_API_URL}/${path}`;
		let body = JSON.stringify(params);

		if (this._logRequest)
			console.log(this._tag, `<- ${url}`);

		let requestInit: globalThis.RequestInit = {
			method: method,
			credentials: 'include',
			headers: HEADERS,
		}

		if (method !== "GET")
			requestInit["body"] = body;

		let response = await fetch(url, requestInit);

		let json = await response.json();

		if (json.error)
		{
			console.error(this._tag, `-> ${json.error}`);
			throw new Error(json.error);
		}

		if (this._logResponse)
			console.log(this._tag, "->", json);

		return json;
	}
}

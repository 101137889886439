import { createHash } from "crypto";

export function encryptPassword(password: string): string
{
	return createHash("sha256").update(password).digest("hex");
}



export const authProvider = {
	login: (params: {username: string, password: string}) => {
		let passHash = encryptPassword(params.password);
		const request = new Request(`${process.env.REACT_APP_API_URL}/login`, {
			method: 'POST',
			body: JSON.stringify({username: params.username, password: passHash}),
			credentials: 'include',
			headers: new Headers({
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}),
		});
		return fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(({token}) => {
				localStorage.setItem('token', "TOKEN");
			});
	},

	logout: (params: object) => {
		localStorage.removeItem('token');
		return Promise.resolve();
	},

	checkAuth: (params: object) => {
		return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
	},

	checkError: (error: object) => Promise.resolve(),
	getPermissions: (params: object) => Promise.resolve(),
};

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IBrand, ICategory, IPartner } from "../../../common/interfaces/records";
import { backend } from "../index";

export class Brand1 extends React.Component<{match: {params: {number: string;};};}, {categories: ICategory[], brand: IBrand}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {categories: [], brand: {name: '', id: '', image: '', pictures: [], section_ids: []}};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<div className={"brand"}>
					<h4>{this.state.brand.name}</h4>
					<CategoryTile categories={this.state.categories}/>
				</div>
			</Container>
		);
	}

	async loadData()
	{
		let categories = await backend.getCategoriesWithBrand(this.props.match.params.number)
		this.setState(_ => ({
			categories: categories,
		}));
		let brand = await backend.getBrand(this.props.match.params.number)
		this.setState(_ => ({
			brand: brand,
		}));
	}
}

class CategoryTile extends React.Component<{categories: ICategory[]}>
{
	render()
	{
		return (
			<Row>
				{this.props.categories.map(product => (
					<Col sm={6} md={4} lg={3} key={product.id}>
						<Link to={`/category/${product.id}`}>
							<div className={"general-shadow title mb-4"}>
								<div className={"overflow-hidden display-center image"}>
									{typeof product.pictures !== 'undefined' ? product.pictures.map(item => (
										<img src={`${item.src}`} alt={`${product.name}`} key={item.src}/>
									)) : ''}
								</div>
								<div className={"bg-highlight text overflow-hidden"}>
									{product.name}
								</div>
							</div>
						</Link>
					</Col>
				))}
			</Row>
		);
	}
}

export class HomeBrands extends React.Component <{}, {brands: IBrand[]}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {brands: []};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<h4 className={"mt-5 d-flex justify-content-center align-items-end"}>Бренди</h4>
				<Row>
					{this.state.brands.map(brand => (
						<>{
							typeof brand.pictures !== 'undefined' ? brand.pictures.map((item, i) => (
									i !== 0 ?
										<div className={"col-sm-4 display-center home-brand"}>
											<Link to={`/brand/${brand.id}`}>
												<img className={'mw-100 mh-100'} src={`${item.src}`} alt={`${brand.name}`}
												     key={item.src}/>
											</Link>
										</div>
										: ''))
								: ''
						}</>
					))}
				</Row>
			</Container>
		);
	}

	async loadData()
	{
		let brands = await backend.getBrands()
		this.setState(_ => ({
			brands: brands
		}));
	}
}

export class HomePartner extends React.Component <{}, {partners: IPartner[]}>
{
	constructor(props: any)
	{
		super(props);
		this.state = {partners: []};
		this.loadData().catch();
	}

	render()
	{
		return (
			<Container>
				<h4 className={"mt-5 d-flex justify-content-center align-items-end"}>Партнери</h4>
				<Row>
					{this.state.partners.map(partner => (
						<>{
							typeof partner.pictures !== 'undefined' ? partner.pictures.map((item, i) => (
									<div className={"col-sm-4 display-center home-brand"} key={partner.id + i}>
										<img className={'mw-100 mh-100'} src={`${item.src}`} alt={`${partner.name}`}
										     key={item.src}/>
									</div>
								))
								: ''
						}</>
					))}
				</Row>
			</Container>
		);
	}

	async loadData()
	{
		let partners = await backend.getPartners()
		this.setState(_ => ({
			partners: partners
		}));
	}
}

